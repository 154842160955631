import logo from './logo/logo.png';
import logoWht from './logo/logoWht.png';
import teaMosaic from './teaMosaic.png';
import wallMosaic from './wallMosaic.png';
import girl from './girl.png';
import lionBlocks from './lionBlocks.png';
import scalesBlocks from './scalesBlocks.png';
import fishMosaic from './fishMosaic.png';
import taurusMosaic from './taurusMosaic.png';
import lionMosaic from './lionMosaic.png';
import scalesMosaic from './scalesMosaic.png';
import carousel1 from './carousel1.png';
import carousel2 from './fon2.png';
import carousel3 from './fon3.png';
import carousel4 from './fon4.png';
import carousel5 from './fon5.png';
import carousel6 from './fon6.png';
import carousel7 from './fon7.png';
import carousel8 from './fon8.png';
import carousel9 from './fon9.png';

import boss from './boss.png';

export const Logo = logo;
export const LogoWht = logoWht;
export const TeaMosaic = teaMosaic;
export const WallMosaic = wallMosaic;
export const Girl = girl;
export const LionBlocks = lionBlocks;
export const ScalesBlocks = scalesBlocks;
export const FishMosaic = fishMosaic;
export const TaurusMosaic = taurusMosaic;
export const LionMosaic = lionMosaic;
export const ScalesMosaic = scalesMosaic;
export const Carousel1 = carousel1;
export const Carousel2 = carousel2;
export const Carousel3 = carousel3;
export const Carousel4 = carousel4;
export const Carousel5 = carousel5;
export const Carousel6 = carousel6;
export const Carousel7 = carousel7;
export const Carousel8 = carousel8;
export const Carousel9 = carousel9;
export const Boss = boss;
